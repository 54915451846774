/* App.css */

/* Set the background image for the body */
body {
  margin: 0;
  padding: 0;
  background-image: url('./assets/background.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

/* Animation for moving background image up and down */
@keyframes moveBackground {
  0% {
    background-position: center top;
  }
  50% {
    background-position: center bottom;
  }
  100% {
    background-position: center top;
  }
}

/* Apply moving background to pages */
.dashboard-page, .start-page, .verify-page, .hint-page, .answer-page, .final-page {
  animation: moveBackground 30s infinite linear;
}

/* Container for the content */
.container {
  position: relative;
  z-index: 2;
  padding: 20px;
  color: #333;
}

/* Heading with falling effect */
@keyframes fallIn {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.heading {
  font-size: 48px;
  text-align: center;
  margin-top: 20px;
  animation: fallIn 1s ease;
  color: #fff;
  text-shadow: 2px 2px 4px #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

/* Adjust styles for forms */
form {
  max-width: 400px;
  margin: 50px auto;
  background-color: rgba(255,255,255,0.9);
  padding: 20px;
  border-radius: 8px;
}

form div {
  margin-bottom: 15px;
}

form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

form input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  font-size: 16px;
}

button:hover {
  background-color: #0066cc;
  color: #fff;
  cursor: pointer;
}

/* Adjust link styles */
a {
  color: #0066cc;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Additional styles */
p {
  text-align: center;
}

.page-content {
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 2px #000;
  margin-top: 50px;
}

.options {
  margin: 20px 0;
}

.options button {
  margin: 0 10px;
}

input[type="text"] {
  padding: 8px;
  width: 200px;
  margin-right: 10px;
}
